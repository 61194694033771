<template>
        
    <div  class="container-fluid" >
        <list-desktop
            v-if="!$isMobile()"
            :empty="empty"
            :loading="loading"
            :data="data"
            :queriedData="queriedData"
            :dataPagination="dataPagination"
            v-on:add_or_edit="addOrEdit"
            v-on:details="details"
            v-on:delete_article="deleteArticle"
            v-on:search_q="setSearch"
            v-on:per_page_selected="nbrPerPageSelected"
        >
        </list-desktop>
        <list-mobile
            v-if="$isMobile()"
            :empty="empty"
            :loading="loading"
            :data="data"
            :queriedData="queriedData"
            :dataPagination="dataPagination"
            v-on:add_or_edit="addOrEdit"
            v-on:details="details"
            v-on:delete_article="deleteArticle"
            v-on:search_q="setSearch"
            v-on:per_page_selected="nbrPerPageSelected"
        >
        </list-mobile>

        <mdb-modal
            centered
            size="lg"
            direction="top"
            position="top"
            :show="modalArticle.show" 
        > 
            <form @submit.prevent="modalArticle.edit ? update() : save()">
            <mdb-modal-header>
                <mdb-modal-title >{{ modalArticle.title }}</mdb-modal-title>
            </mdb-modal-header>
            <mdb-modal-body class="">
                    <div v-if="!modalArticle.edit" class="import-section d-flex justify-content-end">
                        <mdb-btn @click="importArticles()" color="info" size="sm" icon="file-upload" class="px-2">
                            Importer Articles
                        </mdb-btn>
                    </div>
                    <fieldset class="">
                        <legend>Généralité</legend>
                        <mdb-row>
                            <mdb-col sm="12" md="4" lg='4'>
                                <mdb-input wrapperClass="" inputClass=" " labelClass=" " 
                                :validation="$store.state.article.errors.ref ? true :false" :invalidFeedback="$store.state.article.errors.ref"
                                v-model="form.ref"
                                outline label="Réference" ></mdb-input>
                            </mdb-col>
                            <mdb-col sm="12" md="4" lg="8">
                                <mdb-input wrapperClass="" inputClass=" " labelClass=" " 
                                :validation="$store.state.article.errors.designation ? true :false" :invalidFeedback="$store.state.article.errors.designation"
                                v-model="form.designation"
                                outline label="Désignation" ></mdb-input>
                            </mdb-col>
                            <mdb-col sm="12" md="6">
                                <mdb-select flipOnScroll wrapperClass=""   labelClass=" " caretClass="color-primary-reversed "
                                @getValue="getFamily"
                                :validation="$store.state.article.errors.family ? true :false" :invalidFeedback="$store.state.article.errors.family"
                                v-model="familyOption"
                                outline label="Famille" ></mdb-select>
                            </mdb-col>
                            <mdb-col sm="12" md="6">
                                <mdb-select flipOnScroll wrapperClass=""   labelClass=" " caretClass="color-primary-reversed "
                                @getValue="getUnit"
                                :validation="$store.state.article.errors.unit ? true :false" :invalidFeedback="$store.state.article.errors.unit"
                                v-model="unitOption"
                                outline label="Unité de mesure" ></mdb-select>
                            </mdb-col>

                            <mdb-col sm="12" md="6">
                                <mdb-input wrapperClass="" inputClass=" " labelClass=" " 
                                :validation="$store.state.article.errors.puht ? true :false" :invalidFeedback="$store.state.article.errors.puht"
                                v-model="form.puht"
                                type="number" outline label="Prix unitaire HT" ></mdb-input>
                            </mdb-col>

                            <mdb-col sm="12" md="6">
                                <mdb-input wrapperClass="" inputClass=" " labelClass=" " 
                                :validation="$store.state.article.errors.tva ? true :false" :invalidFeedback="$store.state.article.errors.tva"
                                v-model="form.tva"
                                type="number" outline label="TVA" ></mdb-input>
                            </mdb-col>

                            <mdb-col sm="12" md="12">
                                <mdb-input wrapperClass="" inputClass=" " labelClass=" " 
                                v-model="form.describe"
                                type="textarea"  :rows="2" outline label="Description" ></mdb-input>
                            </mdb-col>
                        </mdb-row>
                    </fieldset>
                    <fieldset>
                        <legend class="">Option Avancée</legend>
                        <mdb-row class="">
                            <mdb-col sm="12" md="6" >
                                <label for=""> Y a t-il des taxes spécifiques?</label>
                                <mdb-switch  v-model="form.specific" offLabel="Non" onLabel="Oui"  />
                            </mdb-col>
                            <mdb-col sm="12" md="6" class="" >
                                <label for=""> Y a t-il des taxes de séjour?</label>
                                <mdb-switch  v-model="form.is_sejour" offLabel="Non" onLabel="Oui"  />
                            </mdb-col>
                            <mdb-col sm="12" md="6" >
                                <label for=""> Est-il exonéré de la TVA?</label>
                                <mdb-switch v-model="form.exonere"  offLabel="Non" onLabel="Oui"  />
                            </mdb-col>
                            <mdb-col sm="12" md="6" >
                                <label for=""> Possède des cuves et pistolets?</label>
                                <mdb-switch @click.native="archivedAlert" v-model="form.has_tank_pistol"  offLabel="Non" onLabel="Oui"  />
                            </mdb-col>
                            <mdb-col sm="12" md="6" class="" >
                                <label for="">Etat de l'article?</label>
                                <mdb-switch  v-model="form.is_active" offLabel="Désactivé" onLabel="Activé"  />
                            </mdb-col>
                        </mdb-row>
                    </fieldset>
            </mdb-modal-body>
            <mdb-modal-footer class="">
                <mdb-btn size="md" type="submit" color="primary"  :disabled="modalArticle.btn">
                    <span v-if="!modalArticle.btn">Sauvegarder</span>
                    <span v-if="modalArticle.btn" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    <span v-if="modalArticle.btn" class="pl-2">Loading...</span>
                </mdb-btn>
                <mdb-btn size="md" outline="primary" @click="modalArticle.show = false">Fermer</mdb-btn>
            </mdb-modal-footer>
            </form>
        </mdb-modal>

        <mdb-modal centered    :show="modalArticleDetails.show">
            <mdb-modal-header>
                <mdb-modal-title >
                    <span v-if="modalArticleDetails.content.is_active === 1" class="badge rounded-pill bg-success" style="height:10px; width:10px;display:inline-block"></span>
                    <span v-else class="badge rounded-pill bg-danger" style="height:10px; width:10px;display:inline-block"></span>
                {{ modalArticleDetails.title }}
                </mdb-modal-title>
            </mdb-modal-header>
            <mdb-modal-body class="px-0">
                <table class="modal-details">
                    <tbody>
                        <tr>
                            <th>Ref</th>
                            <td>{{modalArticleDetails.content.ref}} </td>
                        </tr>
                        <tr>
                            <th>Désignation</th>
                            <td>
                                <span v-if="modalArticleDetails.content.is_active === 1" class="badge rounded-pill bg-success" style="height:10px; width:10px;display:inline-block"></span>
                                <span v-else class="badge rounded-pill bg-danger" style="height:10px; width:10px;display:inline-block"></span>
                            {{modalArticleDetails.content.designation}}</td>
                        </tr>
                        <tr>
                            <th>Famille</th>
                            <td>{{modalArticleDetails.content.family}}</td>
                        </tr>
                        <tr>
                            <th>Prix Unitaire HT</th>
                            <td>{{modalArticleDetails.content.puht}}</td>
                        </tr>
                        <tr>
                            <th>TVA</th>
                            <td>{{modalArticleDetails.content.tva}}</td>
                        </tr>
                        <tr>
                            <th>Unité</th>
                            <td>{{modalArticleDetails.content.unit}}</td>
                        </tr>
                        <tr>
                            <th>Exonéré de TVA ?</th>
                            <td>
                                <span v-if="modalArticleDetails.content.exonere === 1"> Oui </span>
                                <span v-else> Non </span>
                            </td>
                        </tr>
                        <tr>
                            <th>Taxe Spécifique ?</th>
                            <td>
                                <span v-if="modalArticleDetails.content.specific === 1"> Oui </span>
                                <span v-else> Non </span>
                            </td>
                        </tr>
                        <tr>
                            <th>Taxe Séjour ?</th>
                            <td>
                                <span v-if="modalArticleDetails.content.is_sejour === 1"> Oui </span>
                                <span v-else> Non </span>
                            </td>
                        </tr>
                        <tr>
                            <th>Possède des cuves et pistolets?</th>
                            <td>
                                <span v-if="modalArticleDetails.content.has_tank_pistol"> Oui </span>
                                <span v-else> Non </span>
                            </td>
                        </tr>
                        <tr>
                            <th>Description</th>
                            <td>{{modalArticleDetails.content.describe}}</td>
                        </tr>
                    </tbody>
                </table>
            </mdb-modal-body>
            <mdb-modal-footer class="">
                <mdb-btn size="md" outline="primary" @click="modalArticleDetails.show = false">Fermer</mdb-btn>
            </mdb-modal-footer>
        </mdb-modal>

        <!-- Import Articles -->
        <mdb-modal
            size="md" 
            direction="top"
            position="top"
            :show="modalArticleImport.show"
        > 
            <form @submit.prevent="saveimportArticles()">
            <mdb-modal-header>
                <mdb-modal-title>{{ modalArticleImport.title }}</mdb-modal-title><br>
            </mdb-modal-header>
            <mdb-modal-body class="">
                <p> Télécharger un modèle de fichier Excel. <a @click="downloadModelArticle()" href="#"> Télécharger </a> <br>
                    <span class="text-warning"> Veuillez à ce que l'index et la référence des articles  soit unique.</span>
                 </p>
                <el-upload
                    class="upload-demo mt-3"
                    ref="articleUpload"
                    :action="$store.state.url+'api/imports/articles'"
                    :headers="{
                    'Authorization':'Bearer '+$store.state.auth.token}"
                    :accept="'.xlsx'"
                    :limit="1"
                    :auto-upload="false"
                    :on-success="importSuccess"
                    :on-error="importError">
                    <el-button slot="trigger" size="medium" type="primary">Ajouter</el-button>
                    <!-- <el-button v-if="$store.state.auth.company.logo" style="margin-left: 10px;" size="small" type="danger" @click="deleteLogo">Supprimer</el-button> -->
                    <div class="el-upload__tip colo-reversed" slot="tip">N'envoyez que des fichiers Excel de type xlsx.</div>
                </el-upload>
            </mdb-modal-body>
            <mdb-modal-footer class="">
                <mdb-btn size="md" color="primary" type="submit"  :disabled="modalArticleImport.btn" >
                    <span v-if="!modalArticleImport.btn">Importer</span>
                    <span v-if="modalArticleImport.btn" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    <span v-if="modalArticleImport.btn" class="pl-2">Loading...</span>
                </mdb-btn>
                <mdb-btn size="md" outline="primary" @click="modalArticleImport.show = false">Fermer</mdb-btn>

            </mdb-modal-footer>
            </form>
        </mdb-modal>
    </div>
</template>

<script>
import ListDesktop from '@/tba/views/articles/modules/ListDesktop'
import ListMobile from '@/tba/views/articles/modules/ListMobile'
import FamilyOption from "@/utils/Options/ArticleFamilyOption"
import UnitOption from "@/utils/Options/UnitOption"
import download from "../../../services/download-fetch"

import {
    mdbBtn,
    mdbSwitch,
    mdbRow,mdbCol,
    mdbSelect,mdbInput,
    mdbModal,mdbModalTitle,mdbModalBody,mdbModalHeader,mdbModalFooter,
} from 'mdbvue'
export default {
    metaInfo: {
      title: 'TECBILL APP',
      titleTemplate: '%s - ARTICLES',
      htmlAttrs: {
        lang: 'fr',
        amp: true
      }
    },
    components:{
        mdbBtn,
        mdbSwitch,
        mdbRow,mdbCol,
        mdbSelect,mdbInput,
        mdbModal,mdbModalTitle,mdbModalBody,mdbModalHeader,mdbModalFooter,

        ListDesktop,ListMobile
    },

    data(){
        return {
            dataPagination: {
                pagination: {
                    perPage:10,
                    currentPage: 1,
                    perPageOptions: [
                        {text:"10",value:"10", selected: true},
                        {text:"25",value:"25"},
                        {text:"50",value:"50"},
                        {text:"100",value:"100"},
                    ],
                    total: 0,
                },
                searchQuery: "",
                propsToSearch: [
                    "famille",
                    "ref",
                    "designation",
                    "describe",
                    "unit",
                    "puht",
                ],
            },

            empty:false,
            loading:false,

            data:{
                columns: [
                    {
                        label: "Ref",
                        field: "ref",
                        sort: true,
                    },
                    {
                        label: "Désignation",
                        field: "designation",
                        sort: true,
                    },
                    {
                        label: "Famille",
                        field: "family",
                        sort: true,
                    },
                    
                    {
                        label: "Prix unitaire HT",
                        field: "puht",
                        sort: true,
                    },
                    {
                        label: "Unite",
                        field: "unit",
                        sort: true,
                    },
                    {
                        label: "",
                        field: "action",
                        sort: true,
                    },
                ],
                rows:[]
            },

            form:{
                id:"",
                ref:"",
                family:"",
                designation:"",
                describe:"",
                unit:"",
                puht:0,
                tva:0,
                specific:false,
                exonere:false,
                is_sejour:false,
                is_active:true,
                has_tank_pistol:false,
                invoice_index:"",
                code_invoice_index:"",
            },

            families:FamilyOption,
            familyOption:FamilyOption,
            units:UnitOption,
            unitOption:UnitOption,

            modalArticleDetails: {
                show: false,
                edit: false,
                title: "Détails du client",
                content: {},
            },
            modalArticle: {
                show: false,
                edit: false,
                title: "Ajouter un article",
                content: {},
                btn:false
            },
            modalArticleImport: {
                show: false,
                edit: false,
                title: "Importer des articles",
                content: {},
                btn:false
            },
        }
    },

    methods:{
        //Fonction de fonctionnement
        addOrEdit(data = null) {
            if (data instanceof Object) {
                this.modalArticle.title = "Modifier un article";
                this.modalArticle.content = data;
                this.modalArticle.edit = true;
                this.formRest(data);
            } else {
                this.modalArticle.title = "Ajouter un article";
                this.modalArticle.content = {};
                this.modalArticle.edit = false;
                this.cleanForm('article');
            }

            this.modalArticle.show = true;
        },

        setSearch(text){
            this.dataPagination.searchQuery = text
        },

        cleanForm(type) {
            if (type === 'article') {
				this.form.id = ""
                this.form.ref = ""
                this.form.designation = ""
                this.form.describe = ""
                this.form.puht = 0
                this.form.tva = 0
                this.form.has_tank_pistol = false
                this.form.specific = false
                this.form.exonere = false
                this.form.is_active = true
                this.form.is_sejour = false
                this.form.invoice_index = ""
			}
        },

        formRest(data) {
            this.form.id = data.id
            this.form.ref = data.ref
            this.form.family = data.family
            this.form.designation = data.designation
            this.form.describe = data.describe
            this.form.unit = data.unit
            this.form.puht = data.puht
            this.form.tva = data.tva
            this.form.has_tank_pistol = data.has_tank_pistol
            this.form.specific = data.specific === 1 ? true: false
            this.form.exonere = data.exonere === 1 ? true: false
            this.form.is_active = data.is_active === 1 ? true: false
            this.form.is_sejour = data.is_sejour === 1 ? true: false
            this.form.invoice_index = data.invoice_index

            this.familyOption = [] 

            this.unitOption = [] 


            this.families.forEach(family => {
                this.familyOption.push({ 
                    text: family.text, 
                    value:family.value,
                    selected: (data.family === family.value) ? true : false })
            })

            this.units.forEach(unit => {
                this.unitOption.push({ 
                    text: unit.text, 
                    value:unit.value,
                    selected: (data.unit === unit.value) ? true : false })
            })
        },

        details(row) {
            this.modalArticleDetails.content = row

            this.modalArticleDetails.title = `${row.designation}`

            this.modalArticleDetails.show = true
            // 
        },

        getUnit(value){
            this.form.unit = value
        },
        
        getFamily(value){
            this.form.family = value
        },

        hiddenHide(){
        },

        async loadArticles () {
            this.loading = !this.loading
			this.$nprogress.start()
            await this.$store.dispatch('article/findAll')
            .then(response => {
                this.$nprogress.done()
                this.loading = !this.loading
                if (response.data.articles.length === 0) {
                    this.empty = true
                }else{
                    this.empty = false
                }
                this.data.rows = response.data.articles
            }).catch((error) => {
				this.$nprogress.done()
                this.loading = !this.loading
                this.$notify({
                    message: error.response.data.message  || error.response.data.error,
                    type: 'danger'
                })
            })
        },

        async save() {
            this.$store.commit('article/SET_CLEAN')
            this.modalArticle.btn = !this.modalArticle.btn
            this.form.code_invoice_index = this.$store.state.auth.company.id+'_'+this.form.invoice_index

			await this.$store.dispatch('article/save', this.form)
			.then((response) => {
				this.modalArticle.show = false
                this.modalArticle.btn = !this.modalArticle.btn
                    this.$notify({
                        message: response.data.message,
                        type: 'success'
                    })
				this.cleanForm('article')
                this.loadArticles()
			})
			.catch((error) => {
                this.modalArticle.btn = !this.modalArticle.btn
                if (error.response.data.errors) {
                    this.$store.commit('article/SET_REF', error.response.data.errors.ref)
                    this.$store.commit('article/SET_FAMILY', error.response.data.errors.family)
                    this.$store.commit('article/SET_DESIGNATION', error.response.data.errors.designation) 
                    this.$store.commit('article/SET_PUHT', error.response.data.errors.puht) 
                    this.$store.commit('article/SET_TVA', error.response.data.errors.tva) 
                    this.$store.commit('article/SET_UNIT', error.response.data.errors.unit) 
                    this.$store.commit('article/SET_ININDEX', (error.response.data.errors.code_invoice_index) ? error.response.data.errors.code_invoice_index : error.response.data.errors.invoice_index) 
                }
                this.$notify({
                    message: error.response.data.message  || error.response.data.error,
                    type: 'danger'
                })
			}) 
        },

        async update() {
            this.$store.commit('article/SET_CLEAN')
            this.modalArticle.btn = !this.modalArticle.btn
            this.form.code_invoice_index = this.$store.state.auth.company.id+'_'+this.form.invoice_index
			await this.$store.dispatch('article/update', this.form)
			.then((response) => {
				this.modalArticle.show = false
                this.modalArticle.btn = !this.modalArticle.btn
                    this.$notify({
                        message: response.data.message,
                        type: 'success'
                    })
				this.cleanForm('article')
                this.loadArticles()
			})
			.catch((error) => {
                this.modalArticle.btn = !this.modalArticle.btn
                if (error.response.data.errors) {
                    this.$store.commit('article/SET_TVA', error.response.data.errors.tva) 
                    this.$store.commit('article/SET_REF', error.response.data.errors.ref)
                    this.$store.commit('article/SET_FAMILY', error.response.data.errors.family)
                    this.$store.commit('article/SET_DESIGNATION', error.response.data.errors.designation) 
                    this.$store.commit('article/SET_PUHT', error.response.data.errors.puht) 
                    this.$store.commit('article/SET_UNIT', error.response.data.errors.unit) 
                    this.$store.commit('article/SET_ININDEX', (error.response.data.errors.code_invoice_index) ? error.response.data.errors.code_invoice_index : error.response.data.errors.invoice_index) 
                }
                this.$notify({
                    message: error.response.data.message  || error.response.data.error,
                    type: 'danger'
                })
			})  
        },

        async deleteArticle(id){

            let res = await this.$store.dispatch("swal/doYouDelete");
            if (res.isDismissed) {
                return;
            }
			await this.$store.dispatch('article/delete',id)
                .then((response) => {
                    this.$notify({
                        message: response.data.message,
                        type: 'success'
                    })
                    this.loadArticles()
                })
                .catch(error =>{
                    this.$notify({
                        message: error.response.data.message  || error.response.data.error,
                        type: 'danger'
                    })
                })
			
		},

        async downloadModelArticle(){
            this.$nprogress.start()
            await download({
                isTh:0,
                method:"get",
                url:'/imports/articles/model',
                filename:"modeles_articles.xlsx"
            }).then(() => {
                this.$nprogress.done();
                this.$notify({
                    message: "Téléchargé avec succès",
                    type: 'success'
                })
            })
            .catch(() => {
                this.$nprogress.done();
                this.$notify({
                    message: "Echec durant le téléchargement",
                    type: 'danger'
                })
            })
        },

        async archivedAlert(e){
            e.preventDefault();
            if(!this.form.has_tank_pistol){
                let res = await this.$store.dispatch("swal/ultimate",{
                    title:"Etes-vous sûre?",
                    message:"Notez que cette action est irréversible.",
                    type:"warning",
                    icon:"warning"
                });

                if (res.isDismissed) {
                    return;
                }
                
                this.form.has_tank_pistol = true;

                return
            }else{
                // this.form.has_tank_pistol = false;
                return
            }
            
        },
        
        importArticles(){
            this.modalArticle.show = false
            this.modalArticleImport.show = true
            // 
        },

        async saveimportArticles(){
            if (this.$refs.articleUpload.uploadFiles.length > 0) {
                this.modalArticleImport.btn = !this.modalArticleImport.btn
                this.$refs.articleUpload.submit()
            }
        },

        async importSuccess(response){
            this.modalArticleImport.btn = !this.modalArticleImport.btn
            this.modalArticleImport.show = false
            await   this.loadArticles()
            this.$notify({
                message: response.message,
                type: 'success'
            })
        },
        importError(error){
            this.modalArticleImport.btn = !this.modalArticleImport.btn
            this.$notify({
                message: JSON.parse(error.message).message,
                type: 'danger'
            })
        },

        // Systeme de datatable
        totalCalcul () {
            return this.dataPagination.pagination.total = this.data.rows.length;
        },

        searchQ () {
            if (!this.dataPagination.searchQuery) {
                // return this.pagedData;
                this.totalCalcul()
                return new Error('Nothing To Search')
            }
            
            let result = this.data.rows.filter((row) => {
                let isIncluded = false;
                for (let key of this.dataPagination.propsToSearch) {
                    let rowValue = "";

                    if(row[key] !== undefined && row[key] !== null){
                        rowValue = row[key].toString().toLowerCase();
                    }
                    if (rowValue.includes && rowValue.includes(this.dataPagination.searchQuery.toLowerCase())) {
                    isIncluded = true;
                    }
                }
                return isIncluded;
            });
            this.dataPagination.pagination.total = result.length;
            return result.slice(this.from, this.to);
        },

        nbrPerPageSelected(value){
            let nbr = value;
            
            return this.nbrPerPage(nbr)
        },

        nbrPerPage (nbr) {
           return this.dataPagination.pagination.perPage = nbr
        },
    },

    created() {
        this.loadArticles()    
    },

    computed: {
        pageData () {
            return this.data.rows.slice(this.from, this.to);
        },

        to() {
            let highBound = this.from + this.dataPagination.pagination.perPage;
            if (this.total < highBound) {
                highBound = this.total;
            }
            return highBound;
        },

        queriedData() {
            let result = this.searchQ();
            return (result instanceof Error) ? this.pageData : result;
        },

        from() {
            return this.dataPagination.pagination.perPage * (this.dataPagination.pagination.currentPage - 1);
        },

        total() { 
            return this.totalCalcul();
        },
    }
}
</script>

<style lang="scss" scoped>
    
</style>